import React from "react";
import HireATalent from "../components/hire-a-talent/HireATalent";

const HireaTalent = () => {
  return (
    <div>
      <HireATalent />
    </div>
  );
};

export default HireaTalent;
